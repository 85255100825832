<template>
  <PageHeader :title="title" :items="items"/>

  <div class="mb-4">
    <Table :name="table.name" :url="table.url" :headers="table.headers" :searchable="true" :selectable="true">

      <template v-slot:top-right>
        <b-button variant="success" size="md" data-bs-toggle="modal"
                  data-bs-target="#generalProductCreate">
          <i class="ri-add-fill align-bottom px-1"></i>
          Create Product
        </b-button>
      </template>

      <template v-slot:actions="props">

        <b-button-group class="mt-4 mt-md-0" role="group" aria-label="Basic example">
          <b-button variant="light" size="md" data-bs-toggle="offcanvas"
                    data-bs-target="#product_actions"
                    aria-controls="product_actions"
                    @click="current_product = props.row">
            <i class="ri-pencil-fill align-bottom px-0"></i>
          </b-button>
        </b-button-group>

      </template>
    </Table>
  </div>

  <ActionsOffcanvas :product="current_product"/>
</template>

<script>
import PageHeader from "@/components/page-header.vue";
import Table from "@/components/tables/table.vue";
import ActionsOffcanvas from "./ProductActions.vue";

export default {
  name: "index",
  components: {
    Table,
    PageHeader,
    ActionsOffcanvas,
  },
  data() {
    return {
      title: "Products",
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "Products",
          active: true,
        },
      ],
      table: {
        name: 'Products Table',
        url: '/core/products/',
        headers: [
          {
            field: 'id',
            label: 'Id',
            align: 'center',
            visible: false
          },
          {
            field: 'name',
            label: 'Name',
            align: 'center',
          },
          {
            field: 'hc_code',
            label: 'Hs Code',
            align: 'center',
          },
          {
            field: 'etcng_code',
            label: 'Etcng Code',
            align: 'center',
          },
          {
            field: 'etcng_name',
            label: 'Etcng Name',
            align: 'center',
          },
          {
            field: 'actions',
            label: 'ACTIONS',
            align: 'center',
          },
        ]
      },
      current_product: {}
    }
  }
}
</script>

<style scoped>

</style>