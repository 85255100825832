<template>
  <div class="offcanvas offcanvas-end" tabindex="-1" id="product_actions" aria-labelledby="product_actionsLabel">
    <div class="offcanvas-header">
      <h5 id="product_actionsLabel">Product Details</h5>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>

    <div class="offcanvas-body pt-0">

      <div class="table-card mt-2" v-if="Object.keys(product_info).length > 0">
        <form @submit.prevent="saveProduct()" v-if="delete_status === false">
          <table class="table mb-0">
            <tbody>
            <tr class="align-middle">
              <td class="fw-medium" style="min-width: 120px">Name</td>
              <td>
                <input class="form-control py-0 border-0" v-model="product_info.name">
              </td>
            </tr>
            <tr>
              <td class="fw-medium">Hs code</td>
              <td>
                <input class="form-control py-0 border-0" v-model="product_info.hc_code">
              </td>
            </tr>
            <tr>
              <td class="fw-medium">Etcng code</td>
              <td>
                <input class="form-control py-0 border-0" v-model="product_info.etcng_code">
              </td>
            </tr>
            <tr>
              <td class="fw-medium">Etcng name</td>
              <td>
                <input class="form-control py-0 border-0" v-model="product_info.etcng_name">
              </td>
            </tr>
            <tr>
              <td class="fw-medium">
                <b-button class="btn btn-light" type="button"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#product_actions"
                          aria-controls="product_actions"
                >Close
                </b-button>
              </td>
              <td class="text-end">

                <b-button v-if="!is_updating" type="submit"
                          :variant="update_status === false ? 'success' : update_status === 200 ? 'success' : 'warning'">
                  {{
                    update_status === false ? 'Save' : update_status === 200 ? 'Saved' : 'Try Again'
                  }}
                </b-button>
                <button v-else-if="is_updating" type="button" class="btn btn-success btn-load">
                                    <span class="d-flex align-items-center">
                                        <span class="spinner-grow flex-shrink-0" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </span>
                                        <span class="flex-grow-1 ms-2">
                                            Saving...
                                        </span>
                                    </span>
                </button>


                <b-button @click="deleteProduct()" class="ms-2"
                          :variant="delete_status === false ? 'danger' : delete_status === 204 ? 'danger' : 'warning'">
                  {{
                    delete_status === false ? 'Delete' : delete_status === 204 ? 'Deleted' : 'Try Again'
                  }}
                </b-button>
              </td>
            </tr>
            </tbody>
          </table>
        </form>
        <div v-else class="ps-3">
          <lottie :options="no_product_animation" :width="200" :height="200"/>
          <h5 class="text-center">Product No Longer Exists</h5>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import axios from "axios";
import Lottie from "@/components/widgets/lottie.vue";
import spxnqpau from "@/components/widgets/spxnqpau.json";

export default {
  name: "actions_offcanvas",
  props: {
    product: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {
    lottie: Lottie,
  },
  data() {
    return {
      update_status: false,
      delete_status: false,
      is_updating: false,
      is_deleting: false,
      no_product_animation: {animationData: spxnqpau},
    }
  },
  computed: {
    product_info() {
      return this.product
    }
  },
  methods: {
    async saveProduct() {
      this.is_updating = true
      try {
        let response = await axios.put('/core/products/' + this.product_info.id + '/update/', {
          name: this.product_info.name,
          hc_code: this.product_info.hc_code,
          etcng_code: this.product_info.etcng_code,
          etcng_name: this.product_info.etcng_name
        })
        this.update_status = response.status
      } catch {
        this.update_status = 500
      }
      this.is_updating = false
    },
    async deleteProduct() {
      this.is_deleting = true
      try {
        let response = await axios.delete('/core/products/' + this.product_info.id + '/delete/')
        this.delete_status = response.status
      } catch {
        this.delete_status = 500
      }
      this.is_deleting = false
    }
  },
  watch: {
    product() {
      this.update_status = false
      this.delete_status = false
      this.is_deleting = false
      this.is_updating = false
    }
  }
}
</script>

<style scoped>

</style>